.button_next_page {
  position: absolute;
  top: 89vh;
  left: 80vw;
  height: 7vh;
  max-height: 55px;
  width: 18vw;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.button_next_page a {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 1vw;
}

.button_previous_page a {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 1vw;
}

.button_previous_page {
  position: absolute;
  top: 89vh;
  left: 52vw;
  height: 7vh;
  max-height: 55px;
  width: 18vw;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

// .button_next_page img {
//   width: auto;
//   height: 100%;
//   border-radius: 160px;
//   pointer-events: none;
//   background-color: transparent;
// }

// .button_next_page p {
//   position: absolute;
//   top: 0;
//   right: 6vw;
//   line-height: 7vh;
//   color: white;
//   font-size: 1vw;
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Old versions of Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently */
// }

// .button_previous_page img {
//   width: auto;
//   height: 100%;
//   border-radius: 160px;
//   pointer-events: none;
// }

// .button_previous_page p {
//   position: absolute;
//   top: 0;
//   left: 6vw;
//   line-height: 7vh;
//   color: white;
//   font-size: 1vw;
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Old versions of Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently */
// }

.button_next_page_mobile {
  position: absolute;
  top: 17vh;
  left: 7%;
  width: 12%;
  border: none;
  background-color: transparent;
  color: transparent;
}

.button_previous_page_mobile {
  position: absolute;
  top: 17vh;
  right: 7%;
  width: 12%;
  border: none;
  background-color: transparent;
  color: transparent;
  transform: rotate(180deg);
}

@media (max-width: 651px) {
  .welcome {
    top: 15vh;
    height: 85vh;
    width: 100%;
    overflow: hidden;
  }
  .category_img {
    width: 100%;
    height: 85vh;
    object-fit: cover;
  }
  .buttons {
    position: fixed;
    top: 85vh;
    left: 10vw;
    width: 60vw;
  }
}
@media (min-width: 651px) {
  .welcome {
    width: 100%;
    height: 89.5vh;
    overflow: hidden;
    background-color: transparent;
  }
  .category_img {
    width: 100vw;
    top: 10.5vh;
    height: 89.5vh;
    object-fit: cover;
  }

  [tabindex] {
    outline: none !important;
  }
}

.myAnim {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
