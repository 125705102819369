.shipping {
  margin-top: 10vh;
  margin-left: 5vw;
}

.shipping h3 {
  font-size: 2.2vmin;
  font-weight: bold;
}

.shipping-options {
  display: flex;
  width: 30vw;
  height: 22vh;
  justify-content: space-evenly;
}
.shipping-options > div {
  width: 33%;
  min-width: 90px;
  border: 1px solid #fbb03b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.shipping-options p {
  color: #a1a1a1;
  opacity: 0.5;
  font-size: 1.8vmin;
  text-align: center;
}

.shipping-options h3 {
  font-weight: bold;
  font-size: 2.2vmin;
  color: black;
  opacity: 0.5;
}

.shipping-options h5 {
  font-weight: bold;
  font-size: 2.2vmin;
  color: black;
}

.shipping-method h1 {
  font-weight: bold;
  font-size: 1.9vmin;
}

.shipping-method {
}

.shipping-top {
  display: flex;
  width: 80vw;
  justify-content: space-between;
}

.shipping-icons {
  display: flex;
  justify-content: space-evenly;
}

.shipping-icons img {
  width: 5vmin;
  height: auto;
}
.horizontal-line {
  margin-top: 2.5vh;
  width: 6vw;
  border-top: 1px solid #eeeeee;
}

.navigation {
  margin-top: 40px;
  display: flex;
  width: 80vw;
  height: 40px;
  justify-content: space-between;
}

.navigation-return {
  display: flex;
}

.navigation-return p {
  font-weight: bold;
  margin-bottom: 0;
  line-height: 40px;
  margin-left: 5px;
  font-size: 1.4vmin;
  cursor: pointer;
}

.navigation-return img {
  width: 2vmin;
  height: auto;
  cursor: pointer;
}

.navigation-forward {
  display: flex;
  justify-content: space-between;
  width: 45vmin;
}

.navigation-forward-continue {
  background-color: white;
  width: 20vmin;
  color: black;
  border-radius: 50px;
  border: 1px solid #d8d8d8;
  height: 4vmin;
  outline: none;
  font-size: 1.4vmin;
  font-weight: bold;
  display: block;
  margin: 0;
}

.navigation-forward-laststep {
  background-color: #e97a41;
  width: 20vmin;
  color: white;
  border-radius: 50px;
  border: none;
  height: 4vmin;
  outline: none;
  font-size: 1.4vmin;
  display: block;
  margin: 0;
}

.shipping-address {
  position: absolute;
  top: 30vh;
  left: 50vw;
}
.shipping-address h1 {
  font-weight: bold;
  font-size: 1.9vmin;
}

.shipping-address p {
  font-size: 1.8vmin;
}

.shipping-order {
}

.shipping-order h1 {
  font-size: 1.9vmin;
  font-weight: bold;
}

.product {
  display: flex;
  width: 40vmax;
  justify-content: space-between;
  align-items: center;
}
.product p {
  width: 100px;
  font-size: 1.9vmin;
}

.product h5 {
  width: 40vmin;
  font-size: 1.9vmin;
}
.product img {
  width: 40px;
  height: 40px;
  margin-right: 2vmin;
}

.summary {
  background-color: #f1f1f1;
  width: 20vmin;
  color: black;
  border-radius: 50px;
  border: none;
  font-size: 2vmin;
  display: block;
  margin: 0;
  font-weight: bold;
  line-height: 4vmin;
  text-align: center;
  position: relative;
  top: -13vh;
}

@media (max-width: 500px) {
  .shipping {
    margin: 0 auto;
    width: 90vmin;
    margin-top: 15vmin;
  }
  .shipping-top {
    display: flex;
    width: 90vw;
    margin: 0;
    justify-content: space-between;
  }

  .shipping-method {
    width: 90vmin;
  }

  .shipping-method h1 {
    font-weight: bold;
    font-size: 3.5vmin;
    text-align: center;
    margin-top: 7vmin;
  }

  .shipping h3 {
    font-size: 5.5vmin;
    font-weight: bold;
    margin: 0 auto;
  }

  .navigation-return {
    display: flex;
    height: 2.9vmin;
    position: absolute;
    top: 18vh;
    left: 2.5vw;
  }

  .navigation-return img {
    width: 5vmin;
    height: auto;
  }

  .navigation-return p {
    font-weight: bold;
    height: 2.9vmin;
    font-size: 3vmin;
    line-height: 2.9vmin;
    margin-left: 5px;
    margin-bottom: 0;
  }
  .shipping-icons {
    display: none;
  }
  .shipping-address {
    position: relative;
    width: 80vmin;
    top: 5vmin;
    left: 0;
    margin: 0 auto;
  }

  .shipping-address p {
    font-size: 4vmin;
    text-align: center;
  }

  .shipping-address h1 {
    font-size: 4vmin;
    text-align: center;
  }

  .shipping-options {
    display: flex;
    width: 40vmin;
    margin-left: 25vmin;
    margin-top: 5vmin;
    height: 50vmin;
    justify-content: space-evenly;
  }
  .shipping-options > div {
    width: 100%;
  }
  .shipping-options p {
    color: #a1a1a1;
    opacity: 0.5;
    font-size: 5vmin;
    text-align: center;
  }

  .shipping-options h5 {
    font-size: 5vmin;
  }

  .shipping-options h3 {
    font-weight: bold;
    font-size: 5vmin;
    color: black;
    opacity: 0.5;
  }
  .shipping-order {
    position: relative;
    top: 10vmin;
    width: 80vmin;
    margin: 0 auto;
    margin-top: 5vmin;
  }
  .shipping-order h1 {
    font-size: 4vmin;
    text-align: center;
  }
  .summary {
    width: 60vmin;
    height: 15vmin;
    border-radius: 0;
    font-size: 4.1vmin;
    line-height: 15vmin;
    margin: 0 auto;
    left: 5vmin;
    top: -15vmin;
  }
  .product {
    width: 80vmin;
  }
  .product p {
    width: 30%;
    font-size: 4vmin;
    margin: 0;
    text-align: center;
  }
  .product h5 {
    width: 70%;
    font-size: 4vmin;
    text-align: center;
  }
  .navigation-forward {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 80vmin;
    height: 35vmin;
    margin: 0 auto;
    margin-top: 5vmin;
  }

  .navigation-forward > button {
    display: none;
  }

  .navigation-forward-laststep {
    position: relative;
    top: 25vmin;
    left: 5vmin;
    width: 80vmin;
    height: 15vmin;
    font-size: 3vmin;
    font-weight: bold;
    margin: 0;
  }
}
