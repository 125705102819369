.quantity-filter {
  margin-right: 2%;
  margin-left: 1%;
  height: 4vh;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;

  &-select-name {
    background-color: #fff;
    outline: none;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    width: auto;
    max-width: 12vw;
    font-size: 1.35vh;
    cursor: pointer;

    &:hover {
      border: 1px solid #5b5a5e;
    }
  }
  &-txt-box {
    margin-left: 0.5vw;
    min-width: 6vw;
    max-width: 12vw;
    min-height: 3vh;
  }
}
.quantity-filter span {
  line-height: 4vh;
}

.img-search {
  height: 100%;
  width: auto;
  // margin-left: 2vw;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .quantity-filter {
    width: 80vw;
    text-align: center;
    margin: 3vh auto;
    justify-content: first baseline;

    &-select-qua {
      margin-left: -20vw;
    }

    &-select-name {
      background-color: #fff;
      outline: none;
      border: 1px solid #d8d8d8;
      border-radius: 50px;
      margin-left: 1vw;
      max-width: 30vw;
      width: 30vw;
      font-size: 1.35vh;
      cursor: pointer;

      &:hover {
        border: 1px solid #5b5a5e;
      }
    }
    &-txt-box {
      margin-left: 3vw;
      max-width: 38vw;
      width: 38vw;
    }
  }
  .img-search {
    margin-left: 1vw;
    cursor: pointer;
  }
  .quantity-filter span {
    // margin-left: 20vw;
  }
}
