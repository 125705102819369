#add-product {
  margin-left: 45vw;
  background-color: #e97a41;
  border-style: none;
  border-radius: 15px;
  outline: none;
  color: white;
  width: 12vw;
  height: 5.5vh;
  font-size: 1vw;
}

.products-list {
  margin: 2%;
}

#modal_with_forms {
  background-color: white;
}

.modal-form {
  color: white;
  height: 80vh;
  width: 80vw;
  overflow-y: scroll;
  position: fixed;
  left: 10%;
  top: 10%;
  outline: "none";
  border-color: #eabf00;
  border: 2px;
  border-style: double;
}

.modal-form-top-sec {
  width: 79vw;
  position: fixed;
}
.modal-form-title {
  text-align: center;
  background-color: black;
  font-size: 1vw;
}

.modal-form-close-sec {
  background-color: white;
}

.modal-form-close-sec-btn {
  background-color: black;
  color: white;
}

.modal-form-submit {
  background-color: #e97a41;
  border-style: none;
  border-radius: 15px;
  margin-left: 25vw;
  margin-bottom: 2vh;
  color: white;
  width: 12vw;
  height: 5.5vh;
  outline: none;
}

.modal-form-btn-add {
  background-color: #e97a41;
  border-style: none;
  border-radius: 15px;
  position: relative;
  top: 14%;
  color: white;
  margin-bottom: 2%;
  width: 12vw;
  height: 5.5vh;
  margin-left: 2%;
}
