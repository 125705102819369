#multiselectContainerReact {
  margin-bottom: 20px;

  float: none;
}

.displayBlock {
  display: block;
}
.contents a {
  display: block;
  margin-bottom: 10px;
  color: #45576b;
  text-decoration: underline;
  width: 74vw;
}
.otheroptions {
  font-size: 14px;
}

/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background: black;
  color: #fff;
}
/* Ends overriding */
