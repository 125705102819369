.login-form {
  margin: 0 auto;
  width: 20vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login-form label {
  margin: 0;
}

.login-form button {
  background-color: #e97a41;
  width: 250px;
  color: white;
  border-radius: 15px;
  border: none;
  height: 40px;
  outline: none;
  font-size: 11px;
}

.login-form input {
  width: 250px;
}

.main-form {
  font-size: 12px;
  font-family: "sans-serif";
  color: black;
  display: flex;
}

.product-form {
  width: 60%;
  margin-left: 20%;
}

.product-form-items {
  margin-top: 5vh;
}

.form-label {
  font-weight: bold;
  margin-right: 20px;
  width: 40vw;
}

#label-value {
  display: flex;
  width: 600px;
}

#images-list {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

#images-list img {
  margin-right: 10px;
}

#images-list div {
  margin-right: 40px;
}

.dropdown-color-czerwony,
.dropdown-color-undefined {
  background-color: red;
}

.dropdown-color-pomarańczowy {
  background-color: orange;
}

.dropdown-color-zielony {
  background-color: green;
}
