$color: #e97a41;

.rounded_button_next {
  text-align: center;
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;

    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: white;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: $color;
    &:before {
      width: 100%;
    }
  }
}

.rounded_button_next p {
  margin: 0;
  padding: 0 8px;
}

.rounded_button_prev p {
  margin: 0;
}

.rounded_button_prev {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0%;
    height: 100%;
    background-color: white;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: $color;
    &:before {
      width: 100%;
    }
  }
}
