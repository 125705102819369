@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  position: relative;
  overflow: hidden;
  min-height: 500px;
}
.welcome {
  width: 100vw;
  height: 89.5vh;
  overflow: hidden;
  pointer-events: none;
  min-height: 500px;
}

.welcome input {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blur {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: 30vw;
  height: 40vh;
  top: 25%;
  left: 0;
  backdrop-filter: blur(10px);
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.blur h1,
.blur h2,
.blur p {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.blur p {
  margin-left: 8%;
  color: white;
  font-size: 0.9vw;
}

.blur h1 {
  margin-left: 8%;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  letter-spacing: 3px;
}

.blur h2 {
  margin-left: 8%;
  color: white;
  font-size: 2.5vw;
  font-weight: bold;
  letter-spacing: 1.14px;
}

.welcome-button {
  margin-left: 8%;
  height: 5.5vh;
  min-width: 130px;
  min-height: 20px;
  width: 16vw;
  object-fit: cover;
}

.welcome-button input {
  width: 100%;
  height: 100%;
  outline: none;
}

.icons-bottom {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  top: 82%;
  left: 39%;
  height: 15vh;
  width: 60vw;
  position: absolute;
}

.stock-button,
.contact-button,
.shop-button {
  min-width: 200px;
  max-width: 220px;
  width: 15vw;
  height: 15vh;
  position: relative;
}

.shop-button input,
.stock-button input,
.contact-button input {
  width: 100%;
  height: 100%;
  outline: none;
}

@media (max-width: 600px) {
  .icons-bottom {
    top: 25%;
    left: 54%;
    width: 48vw;
    height: 40vh;
    flex-wrap: wrap;
  }

  .stock-button,
  .contact-button,
  .shop-button {
    min-width: auto;
    width: 50vw;
    height: auto;
  }

  .shop-button input,
  .stock-button input,
  .contact-button input {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .welcome {
    width: 100vw;
    height: 85vh;
    overflow: hidden;
  }

  .blur {
    width: 55%;
    height: 40%;
  }
  .welcome-button {
    left: 6%;
    top: 67%;
  }

  .blur h1 {
    font-size: 6vw;
  }
  .blur h2 {
    font-size: 6vw;
  }
  .blur p {
    display: none;
  }
}
