@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 6vh;
}

.footer p {
  margin: 0;
  display: inline-block;
  font-size: 0.7vw;
}

.footer a {
  text-decoration: none;
  color: black;
  font-size: 0.7vw;
}
.photo-mobile {
  display: none;
}

.photo-desktop {
  width: 100vw;
  height: 80.5vh;
  overflow: hidden;
  pointer-events: none;
}

.photo-desktop input {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  position: relative;
}

.white-foreground {
  top: 32%;
  left: 40%;
  width: 60vw;
  height: 52.95vh;
  background-color: white;
  position: absolute;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.form-button {
  top: 0;
  left: -5vw;
  z-index: 0;
  width: 5vw;
  height: 9vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.form-button input {
  width: 100%;
  height: 100%;
}

.form-button-edit {
  width: 2.5vw;
  height: 2.5vw;
  align-self: center;
  position: relative;
  top: -6.3vw;
  pointer-events: all;
}

.form-button-edit input {
  outline: none;
}

.form-button p {
  color: white;
  font-size: 0.8vw;
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  top: -6vw;
  letter-spacing: 0.3px;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 55%;
  height: 100%;
}

.info-section {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  left: 60%;
  height: 90%;
  margin-top: 3%;
}

.info-section p {
  font-size: 0.6vw;
  margin: 0;
  letter-spacing: 0.3px;
}

.info-section input {
  margin-right: 8px;
  align-self: center;
}

.phone-section {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
}
.phone-section a {
  display: flex;
}

.phone-section input {
  width: 1.85vw;
  height: 1.85vw;
  outline: none;
}

.address-section {
  display: flex;
  align-items: center;
}

.address-section input {
  width: 1.85vw;
  height: 1.8vw;
  pointer-events: none;
}

.email-section {
  display: flex;
  align-items: center;
}

.email-section a {
  display: flex;
}

.email-section input {
  width: 1.85vw;
  height: 1.447vw;
  outline: none;
}

.hours-section {
  display: flex;
  align-items: center;
}

.hours-section input {
  width: 1.85vw;
  height: 1.8vw;
  pointer-events: none;
}

.facebook-section {
  display: flex;
  align-items: center;
}

.facebook-section input {
  width: 1.85vw;
  height: 1.85vw;
  outline: none;
}

@media (min-width: 551px) {
  .form-button-mobile {
    display: none;
  }

  .map-mobile {
    display: none;
  }
  .facebook-section-mobile {
    display: none;
  }
}

@media (max-width: 550px) {
  .photo-desktop {
    display: none;
  }
  .photo-mobile {
    display: block;
    position: absolute;
    width: 100vw;
    height: 85vh;
  }
  .photo-mobile input {
    width: 100%;
    height: 85vh;
    object-fit: cover;
  }
  .form-button {
    display: none;
  }

  .form-button-edit {
    display: none;
  }

  .white-foreground {
    top: 13vh;
    left: 2.5vw;
    width: 95vw;
    height: 71vh;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
  }
  .map {
    display: none;
  }

  .facebook-section-desktop {
    display: none;
  }

  .info-section {
    top: 4%;
    left: 0;
    height: 65%;
  }

  .info-section p {
    font-size: 0.65em;
  }
  .phone-section,
  .address-section,
  .email-section,
  .hours-section,
  .facebook-section {
    width: 70vw;
    height: auto;
    margin-left: 16vw;
    display: flex;
    align-items: center;
  }

  .phone-section p,
  .address-section p,
  .email-section p,
  .hours-section p,
  .facebook-section p {
    font-size: 3vmin;
    width: 60vw;
  }

  .phone-section {
    display: flex;
    align-items: center;
  }

  .phone-section a {
    display: flex;
  }

  .phone-section input {
    width: 25.57px;
    height: 25.57px;
  }

  .phone-section {
    order: 1;
  }

  .email-section {
    order: 2;
  }

  .address-section {
    order: 3;
  }

  .hours-section {
    order: 4;
  }

  .facebook-section {
    order: 5;
  }

  .email-section a {
    display: flex;
  }

  .email-section input {
    width: 25.43px;
    height: 19.9px;
  }

  .address-section input {
    width: 25px;
    height: 24px;
    pointer-events: none;
  }

  .hours-section input {
    width: 25.01px;
    height: 25.01px;
    pointer-events: none;
  }

  .facebook-section input {
    width: 25.01px;
    height: 25.01px;
  }

  .facebook-section-mobile {
  }

  .form-button-mobile {
    position: relative;
    top: 52vh;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20vw;
    width: 55vw;
    height: 6.5vh;
    background-color: rgba(245, 186, 3);
    border-radius: 45px;
  }

  .form-button-mobile input {
    width: 85%;
    height: auto;
    outline: none;
  }
  .form-button-mobile p {
    color: white;
    font-size: 3vw;
    font-weight: bold;
    margin-left: 3vw;
    margin-bottom: 0;
  }

  .map-mobile {
    position: relative;
    top: 54vh;
    left: 0;
    margin-left: 20vw;
    width: 55vw;
    height: 6.5vh;
    background-color: rgba(70, 142, 201);
    border-radius: 45px;
  }

  .map-mobile p {
    color: white;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    line-height: 6.5vh;
    margin: 0;
  }

  .footer {
    display: none;
  }
}
