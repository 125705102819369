.login {
  margin-top: 20vh;
  margin-left: 40vw;
  width: 20vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login h3 {
  margin-bottom: 8vh;
  font-weight: bold;
  font-size: 3.5vmin;
}

.login-input {
  display: flex;
  flex-direction: column;
  height: 8vmin;
  justify-content: space-between;
}

.login-input input {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 4vmin;
  width: 35vmin;
  padding: 2.5vmin;
  outline: none;
  font-size: 1.3vmin;
  margin-bottom: 2vh;
}

.login button {
  background-color: #e97a41;
  color: white;
  border-radius: 50px;
  border: none;
  height: 4vmin;
  width: 35vmin;
  outline: none;
  font-size: 1.3vmin;
}

.save-my-pass {
  display: flex;
  margin-top: 7vh;
  justify-content: space-between;
}

.save-my-pass-left {
  display: flex;
}

.save-my-pass-right {
}

.save-my-pass-right p {
  cursor: pointer;
}

.save-my-pass input {
  margin-right: 1vw;
}

.save-my-pass p {
  font-size: 1.2vmin;
}

.pass-remainder {
  color: #808080;
}

.register {
  margin-top: 2vh;
  display: flex;
  justify-content: space-evenly;
  width: 35vmin;
}

.register p {
  font-size: 1.2vmin;
}

.register > p {
  cursor: pointer;
}

@media (max-width: 500px) {
  .login {
    margin-top: 10vh;
    margin-left: 2.5vw;
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login h3 {
    font-size: 6.5vmin;
  }
  .login-input {
    height: 40vmin;
  }
  .login-input input {
    height: 18vmin;
    width: 90vmin;
    font-size: 4vmin;
    padding: 5vmin;
  }
  .login button {
    height: 18vmin;
    border-radius: 0;
    width: 100vmin;
    outline: none;
    font-size: 3.5vmin;
  }
  .save-my-pass p {
    font-size: 3.5vmin;
    margin-bottom: 10vmin;
  }
  .register p {
    font-size: 3.5vmin;
  }
  .register {
    margin-top: 10vmin;
    width: 80vmin;
  }
}
