@media (max-width: 450px) {
  .cards_sec {
    position: relative;
    top: 1vh;
  }

  .back_img {
    width: 100%;
    position: absolute;
    height: 85vh;
    overflow: hidden;
    object-fit: cover;
  }

  .card_page {
    width: 100%;
    top: 15vh;
    overflow: hidden;
    background-color: #2f442e !important;
    opacity: 0.65;
    position: absolute;
    height: 85vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .card_text_main {
    position: relative;
    top: 17vh;
    font-size: 11.56vw;
    font-weight: bold;
    margin-left: 20%;
    width: 70%;
    text-align: center;
    text-align: left;
  }

  .card_text_main_second {
    position: relative;
    top: 15vh;
    font-size: 11.16vw;
    font-weight: bold;
    margin-left: 20%;
    width: 70%;
    text-align: center;
    text-align: left;
  }

  .card_text_section {
    position: relative;
    font-size: 4.5vw;
    top: 9vh;
    margin-left: 20%;
    text-align: left;
    color: white;
    line-height: 3vh;
    margin-bottom: 5%;
    width: 55%;
    margin-top: 5%;
  }

  .card_button {
    top: 10vh;
    width: 40%;
    height: 5%;
    margin-left: 20%;
    position: relative;
    background-color: #2f442e !important;
    color: white;
  }

  .card_body_sec {
    width: 80%;
    position: relative;
    height: 5vh;
  }
  .card_button_text {
    top: auto;
    font-size: 0.3em;
  }

  .btn_redirect {
    top: 85vh;
    position: relative;
    left: 30%;
    width: 38%;
    height: 40%;
  }
}

/* Monitor section */
@media (min-width: 451px) {
  .viewport-scroll {
    position: fixed;
    top: 11vh;
    left: 1.7vw;
    width: 6vw;
    height: 6vw;
  }
  .back_img {
    top: 10.5h;
    height: 89.5vh;
    width: 100%;
    object-fit: cover;
  }

  .back_init {
    height: 89.5vh;
  }

  .cards_sec {
    position: relative;
    width: 100%;
  }

  .card_page {
    width: 35vw;
    top: 40%;
    height: 22.5vw;
    overflow: hidden;
    border-top-right-radius: 5% !important;
    border-bottom-right-radius: 5% !important;
    background-color: #2f442e !important;
    opacity: 0.9;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .card_text_main {
    top: 5vh;
    font-weight: bold;
    font-size: 4.13vw;
    margin-left: 21%;
  }

  .card_text_main_second {
    top: 3vh;
    font-weight: bold;
    margin-left: 21%;
    font-size: 3.82vw;
  }

  .card_text_section {
    font-size: 1vw;
    margin-left: 21%;
    position: relative;
    color: white;
    margin-top: -1.5vh;
  }

  .card_button {
    position: relative;
    margin-left: 21%;
    background-color: #2f442e;
    top: 0vh;
    width: 9vw;
    height: 3.5vh;
    color: white;
  }

  .card_button_text {
    top: auto;
    font-size: 100%;
    margin-left: 21%;
  }

  .btn_redirect {
    position: relative;
    left: 63vw;
    width: 25vw;
    height: 15vh;
  }
}

/*common*/

.card_page {
  position: absolute !important;
}

.body_page {
  width: 100%;
}

.button_page:hover {
  background-color: rgb(179, 43, 43);
}

.card_text_main {
  position: relative;
  color: white;
}

.card_text_main_second {
  position: relative;
  margin-top: -2.5%;
  margin-bottom: 5vh;

  color: white;
}

.card_button:hover {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

.card_button_text {
  color: white;
}

.btn_redirect:hover {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
