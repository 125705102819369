.remainder {
  margin-top: 20vh;
  margin-left: 40vw;
  width: 20vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remainder h3 {
  margin-bottom: 4vh;
  text-align: center;
  font-weight: bold;
  font-size: 3vmin;
  width: 35vmin;
}

.remainder input {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 5vmin;
  width: 35vmin;
  padding: 2vmin;
  outline: none;
  margin-bottom: 2vh;
  font-size: 1.2vmin;
}

.remainder button {
  background-color: #e97a41;
  color: white;
  border-radius: 50px;
  border: none;
  height: 5vmin;
  width: 35vmin;
  outline: none;
  font-size: 1.2vmin;
}

@media (max-width: 500px) {
  .remainder {
    margin-left: 10vmin;
    width: 80vmin;
    height: 70vmin;
  }
  .remainder h3 {
    font-size: 6.5vmin;
    width: 80vmin;
  }
  .remainder input {
    height: 18vmin;
    width: 80vmin;
    padding: 5vmin;
    outline: none;
    margin-bottom: 2vh;
    font-size: 3.5vmin;
  }

  .remainder button {
    height: 18vmin;
    width: 80vmin;
    font-size: 3.5vmin;
  }
}
