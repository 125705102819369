.register {
  margin-top: 20vh;
  margin-left: 20vw;
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register h3 {
  margin-bottom: 8vh;
  width: 60vw;
  text-align: center;
  font-weight: bold;
  font-size: 2.5vmin;
}

.register input[type="text"],
.register input[type="password"] {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 5vmin;
  width: 35vmin;
  padding: 2vmin;
  outline: none;
  margin-bottom: 2vh;
  font-size: 1.2vmin;
}

.register button {
  background-color: #e97a41;
  min-width: 35vmin;
  color: white;
  border-radius: 50px;
  border: none;
  min-height: 5vmin;
  outline: none;
  font-size: 1.2vmin;
  display: block;
  margin: 0 auto;
}

.register-fields {
  width: 50vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.register-fields > div {
  display: flex;
  flex-direction: column;
}

.register-fields label {
  font-size: 1.5vmin;
}

.register p {
  font-size: 1.3vmin;
}

.statement {
  display: flex;
  justify-content: center;
}

.statement input {
  height: 2vmin;
  margin-right: 10px;
  margin-bottom: 10px;
}

.statement p,
a {
  height: 2vmin;
  line-height: 2vmin;
  font-size: 1.3vmin;
  margin: 0;
}

@media (max-width: 500px) {
  .register {
    margin-top: 5vh;
    margin-left: 2.5vw;
    width: 95vw;
    height: auto;
  }
  .register h3 {
    width: 90vw;
    font-size: 6.5vmin;
  }
  .register input[type="text"],
  .register input[type="password"] {
    height: 18vmin;
    width: 90vmin;
    padding: 5vmin;
    margin-bottom: 2vh;
    font-size: 4vmin;
  }
  .register-fields label {
    font-size: 3.5vmin;
  }
  .register p {
    font-size: 3.5vmin;
  }

  .statement input {
    height: 4vmin;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .statement p,
  a {
    height: 4vmin;
    line-height: 4vmin;
    font-size: 3vmin;
    margin: 0;
  }
  .register button {
    width: 100vw;
    min-height: 18vmin;
    font-size: 3.5vmin;
    border-radius: 0;
    margin: 2vh 0 0 0;
  }
}
