@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  width: 80%;
  margin: 50px auto 0 auto;
}

@media only screen and (max-width: 1024px) {
  body {
    .filters {
      width: 20%;
    }

    .shelf-container {
      width: 80%;

      .shelf-item {
        width: 33.33%;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  body {
    .filters {
      width: 25%;
    }

    .shelf-container {
      width: 75%;

      .shelf-item {
        width: 50%;
        // padding: 10px;

        &__title {
          margin-top: 5px;
          padding: 0;
        }
      }
    }

    .float-cart {
      width: 100%;
      right: -100%;

      &--open {
        right: 0;
      }

      &__close-btn {
        left: 0px;
        z-index: 2;
        background-color: #e97a41;
      }

      &__header {
        padding: 25px 0;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  body {
    main {
      display: flex;
      flex-wrap: wrap;
      // padding: 2%;
      margin-top: 42px;
    }

    .filters {
      width: 100%;
      margin-right: 0;
      text-align: center;

      .title {
        margin-bottom: 15px;
      }
    }

    .shelf-container-header {
      height: auto;
      font-size: 12px !important;
      .products-found {
        width: 100%;
        text-align: center;
        margin: 10px 0;
      }

      .sort {
        width: 100%;
        text-align: center;
      }
    }

    .shelf-container {
      width: 100%;
      max-height: unset;

      .shelf-item {
        width: 50%;

        &__buy-btn {
          display: none;
        }
      }
    }
  }
}
