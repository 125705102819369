.viewport-scroll {
  position: fixed;
  top: 11vh;
  left: 1.7vw;
  width: 6vw;
  height: 6vw;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.background-desktop {
  overflow: hidden;
  pointer-events: none;
}

.background-desktop input {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-mobile {
  display: none;
}

.brief-history {
  position: absolute;
  top: 13%;
  left: 6%;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.brief-history p {
  margin-top: 2%;
  width: 40%;
  font-size: 1.024vw;
}

.brief-history h5 {
  margin-top: 2%;
  width: 40%;
  font-size: 1.35vw;
  font-weight: bold;
}

.brief-history h1 {
  width: 50%;
  font-weight: bold;
  font-size: 3.3vw;
  letter-spacing: 1.3px;
}
.timeline {
  position: absolute;
  top: 50%;
  left: 6%;
}

.timeline p {
  font-size: 1.5vw;
}

.timeline h3 {
  font-size: 2.5vw;
  font-weight: bold;
}

.timeline-arrow {
  position: absolute;
  top: 60%;
  left: 6%;
  width: 85%;
}

.timeline-arrow-desktop {
  height: 3vh;
}

.timeline-arrow-desktop input {
  width: 100%;
  height: 100%;
}

.timeline-arrow-mobile {
  display: none;
}

.timeline-arrow-dots {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
}

.dot-1 input,
.dot-2 input,
.dot-3 input {
  width: 100%;
  height: 100%;
  object-fit: contain;
  outline: none;
}

.dot-1 h5,
.dot-2 h5,
.dot-3 h5 {
  font-weight: bold;
  font-size: 1.5vw;
  margin: 0;
}

.footer {
  display: flex;
  width: 80%;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  top: 70%;
  left: 6%;
}

.footer-image {
  width: 50%;
  height: auto;
  pointer-events: none;
}

.footer-image input {
  width: 100%;
  height: auto;
}

.footer-text {
  width: 45%;
  height: auto;
}

.story3 p,
.story2 p,
.story1 p {
  font-size: 0.94vw;
}

.story1-year,
.story2-year,
.story3-year {
  font-weight: normal;
  font-size: 1.15vw;
}

.story1-title,
.story2-title,
.story3-title {
  font-weight: bold;
  font-size: 1.3vw;
  margin-bottom: 3vh;
}

@media (max-width: 550px) {
  .viewport-scroll {
    position: fixed;
    top: 14vh;
    left: 1.7vw;
    width: 25vw;
    height: 6vw;
  }
  .background-mobile {
    display: block;
  }

  .background-desktop {
    display: none;
  }

  .background-mobile input {
    width: 100%;
    height: auto;
  }

  .timeline {
    top: 45%;
    width: 10%;
  }

  .timeline > h3,
  .timeline > p {
    display: none;
  }

  .timeline-arrow-desktop {
    display: none;
  }

  .timeline-arrow {
    height: 20%;
    width: 30px;
    top: 37%;
    left: 45%;
    position: absolute;
  }

  .timeline-arrow input {
    width: 100%;
    height: 100%;
  }

  .timeline-arrow-mobile {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .timeline-arrow-dots {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 20%;
    width: 100%;
    height: 80%;
  }

  .dot-1,
  .dot-2,
  .dot-3 {
    width: 5vw;
    height: 5vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  .dot-1 input,
  .dot-2 input,
  .dot-3 input {
    width: 100%;
    height: auto;
  }

  .dot-1 h5,
  .dot-2 h5,
  .dot-3 h5 {
    font-weight: bold;
    font-size: 4vw;
    margin: 0;
    margin-right: 80%;
  }

  .brief-history {
    top: 18%;
    left: 20%;
    width: 60%;
  }

  .brief-history p {
    margin-top: 3vh;
    font-size: 3vw;
    width: 100%;
    line-height: 17px;
  }

  .brief-history h1 {
    font-size: 7.5vw;
    width: 100%;
  }

  .brief-history h5 {
    margin-top: 3vh;
    width: 60%;
    font-size: 3.1vw;
    width: 100%;
    line-height: 17px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    top: 58%;
    width: 80vw;
    left: 10vw;
  }

  .footer-image {
    width: 90vw;
    height: auto;
  }

  .footer-image input {
    width: 100%;
    height: auto;
  }

  .footer-text {
    width: 90%;
    height: auto;
  }
  .story3 p,
  .story2 p,
  .story1 p {
    margin-top: 3vh;
    font-size: 3vw;
    line-height: 25px;
  }

  .story1-year,
  .story2-year,
  .story3-year {
    display: none;
  }

  .story1-title,
  .story2-title,
  .story3-title {
    margin-top: 3vh;
    font-weight: bold;
    font-size: 4vw;
    line-height: 17px;
  }
}
