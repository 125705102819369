@media screen and (min-width: 650px) {
  .quantity-filter {
    float: right;
    margin-right: 2%;
    margin-left: 0%;
    font-weight: bold;

    &-select-qua {
      background-color: #fff;
      outline: none;
      border: 1px solid #d8d8d8;
      border-radius: 50px;
      margin-left: 1vw;
      width: auto;
      max-width: 12vw;
      font-size: 1.35vh;
      height: 4vh;
      cursor: pointer;

      &:hover {
        border: 1px solid #5b5a5e;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .quantity-filter {
    width: 100%;
    text-align: center;
    margin: 10px 0;
    &-select-qua {
      background-color: #fff;
      outline: none;
      border: 1px solid #d8d8d8;
      border-radius: 50px;
      margin-left: 1vw;
      width: 10vw;
      font-size: 1.35vh;
      height: 4vh;
      cursor: pointer;

      &:hover {
        border: 1px solid #5b5a5e;
      }
    }
  }
}
