.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.logo input {
  width: 15vmin;
  height: auto;
  outline: none;
}

.navbar {
  width: 40vw;
  margin-right: 5vw;
}

.navbar-block {
  display: block;
}

.navbar-block p {
  margin: 0;
  font-size: 1.6vmin;
}

.navbar-flex {
  margin-top: 2%;
  display: flex;
  align-items: center;
}

.navbar-flex input {
  margin-right: 5px;
  outline: none;
  width: 3vmin;
}

.phone {
  width: 180px;
  height: 30px;
  position: relative;
  left: 0;
  top: 2vh;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.center {
  width: 180px;
  height: 30px;
  position: absolute;
  border-radius: 10px;
}

.btn {
  width: 180px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #e97a41;
  transition: 1s ease-in-out;
  border-radius: 10px;
  outline: none !important;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #e97a41;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
  border-radius: 10px;
}

.btn:hover {
  transition: 1s ease-in-out;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: black;
  font-size: calc(8px + 0.1vw);
  vertical-align: middle;
  line-height: normal;
}

.navbar_links > div {
  display: flex;
  justify-content: center;
}

@media (max-width: 766px) {
  .navbar-block p {
    font-size: 3.6vmin;
  }
  .bottom {
    display: none;
  }
  .all {
    display: unset;
  }
  .container {
    display: flex;
    justify-content: baseline;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 15vh;
    z-index: 2;
  }
  .logo_phone {
    width: 100vw;
    flex-direction: column;
  }

  .logo input {
    width: 100%;
    object-fit: cover;
  }
  .navbar {
    width: 100%;
  }
  .logo {
    width: 170px;
    margin-left: 2.5vw;
    margin-bottom: 0;
    margin-top: 0;
  }
  .phone {
    display: none;
  }

  .navbar_links {
    position: absolute;
    top: 0;
    left: 27.5vw;
    bottom: 1000%;
    width: 45vw;
    height: 0;
    transition: 1s ease;
  }
  .navbar_links > div {
    display: none;
  }

  .navbar_links.open {
    display: flex;
    top: 15vh;
    height: 70vh;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    bottom: 0;
  }

  .navbar_links.open input {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar_links.open > div {
    z-index: 3;
    display: flex;
  }

  .navbar_links.close a {
    display: none;
  }

  .navbar_links__overlay {
    position: absolute;
    bottom: 100%;
    background-color: gray;
    width: 100%;
    height: 0;
    opacity: 0;
    z-index: 1;
    left: 0;
    right: 0;
    overflow: hidden;
    /* transition: 0.5s ease; */
  }

  .navbar_links__overlay.open {
    bottom: -2000px;
    height: 3000px;

    opacity: 0.95;
  }

  .navbar_links__overlay.close {
    opacity: 0;
  }

  .my_burger {
    z-index: 2;
    margin-right: 2vmin;
  }
  .navbar-flex input {
    width: 10vmin;
  }
}

@media (min-width: 767px) {
  .all {
    display: unset;
  }
  .container {
    height: 12.5vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .logo {
    margin-left: 3%;
    margin-top: 1vh;
    width: 20%;
    height: 95%;
  }

  .navbar_links {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }

  .my_burger {
    display: none;
  }
}

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}
.burger .burger-lines:after {
  left: 0;
  top: -1em;
}
.burger .burger-lines:before {
  left: 1em;
  top: 1em;
}

.burger:after {
  content: "";
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}
.burger .burger-lines {
  top: 50%;
  margin-top: -0.125em;
}
.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  pointer-events: none;
  display: block;
  content: "";
  width: 100%;
  border-radius: 0.25em;
  background-color: rgb(205, 133, 63);
  height: 0.25em;
  position: absolute;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.burger.burger-squeeze .burger-lines,
.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
  -webkit-transition: 0.2s top 0.2s, 0.1s left, 0.2s transform,
    0.4s background-color 0.2s;
  -o-transition: 0.2s top 0.2s, 0.1s left, 0.2s transform,
    0.4s background-color 0.2s;
  transition: 0.2s top 0.2s, 0.1s left, 0.2s transform,
    0.4s background-color 0.2s;
}

.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
  width: 2em;
}

.burger.burger-squeeze.open .burger-lines,
.burger.burger-squeeze.open .burger-lines:after,
.burger.burger-squeeze.open .burger-lines:before {
  -webkit-transition: 0.2s background-color, 0.2s top, 0.2s left,
    0.2s transform 0.15s;
  -o-transition: 0.2s background-color, 0.2s top, 0.2s left,
    0.2s transform 0.15s;
  transition: 0.2s background-color, 0.2s top, 0.2s left, 0.2s transform 0.15s;
}

.burger.burger-squeeze.open .burger-lines {
  background-color: transparent;
}

.burger.burger-squeeze.open .burger-lines:before,
.burger.burger-squeeze.open .burger-lines:after {
  left: 0.5em;
  top: 0px;
}

.burger.burger-squeeze.open .burger-lines:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger.burger-squeeze.open .burger-lines:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
