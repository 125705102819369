.buttons {
  text-align: center;
  border-radius: 30px;
  display: inline-block;
}

.buttons img {
  margin-left: 7vw;
}

.okleiny_california_img {
  position: relative;
  top: 1.5vh;
  left: 2vw;
}

/* begining of mobile section */
@media (max-width: 651px) {
  .color_pallet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 6vh;
    left: 0;
  }

  .color_pallet p {
    font-size: 3vw;
    margin: 0;
  }

  .color_pallet img {
    width: 12%;
    height: auto;
    margin-top: 2vw;
    filter: brightness(150%);
  }

  .text_box_item_okleiny {
    font-size: 2.7vw;
    line-height: 0vh;
    letter-spacing: 0.36px;
    margin-bottom: 2.5vh !important;
    margin-left: 2.75vw;
  }

  .text_box_item_okleiny div {
    margin-bottom: 1.75vh !important;
  }

  .text_box_subtitle_second_okleiny {
    position: relative;
    font-size: 2.7vw;
    line-height: 2.5vh;
    margin-bottom: 2vh !important;
    margin-top: 2vh !important  ;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.36px;
    margin-left: 8.95vw !important;
  }

  .text_box_okucia {
    position: absolute;
    background-color: transparent;
    top: 25.5vh;
    width: 85;
    margin-left: 9.25vw;
    color: white;
    font-family: "Open Sans, Regular";
    /* height: 100%; */
    margin-bottom: 1vh;
    line-height: 3vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .text_box_item_okucia {
    font-size: 2.7vw;
    line-height: 0vh;
    letter-spacing: 0.36px;
    margin-bottom: 2.5vh !important;
  }

  .text_box_item_okucia div {
    margin-bottom: 1.5vh !important;
    margin-top: 1vh !important;
  }

  .text_box_okleiny {
    position: absolute;
    background-color: transparent;
    top: 25.5vh;
    width: 87vw;
    color: white;
    font-family: "Open Sans";
    margin-left: 5vw;
    /* height: 100%; */
    margin-bottom: 1vh;
    line-height: 1vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .text_box {
    top: 23.5vh;
    color: white;
    width: 85vw;
    font-family: "Open Sans, Regular";
    margin-left: 9.25vw;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .text_box_item p {
    margin: 1.75vh;
  }

  .text_box_obrzeza {
    top: 25.5vh;
    color: white;
    width: 85vw;
    font-family: "Open Sans, Regular";
    margin-left: 9.25vw;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .text_box_title {
    font-size: 7.04vw;
    text-align: center;
    font-weight: bold;
    bottom: 2vh;
  }

  .text_box_item {
    font-size: 2.7vw;
    line-height: 0.45vh;
    width: 85vw;
    letter-spacing: 1px;
    font-weight: lighter;
    font-family: "Open Sans, Regular";
  }

  .text_box_subtitle {
    position: relative;
    font-size: 2.7vw;
    margin-bottom: 3vh;
    text-align: left;
    font-weight: bolder;
    letter-spacing: 1px;
    margin-left: 8.95vw;
    width: 65vw;
  }

  .text_box_subtitle_second {
    position: relative;
    font-size: 2.7vw;
    line-height: 2.5vh;
    margin-bottom: 1vh;
    margin-top: 1vh;
    text-align: left;
    letter-spacing: 1px;
    margin-left: 8.95vw;
  }

  .text_space {
    margin-left: 10vw !important;
    margin-bottom: 3vh !important;
  }

  .moveTo_sec {
    position: relative;
    top: 2.5vh;
    margin-right: auto;
  }

  .moveTo_img {
    position: relative;
    width: 50vw;
    height: 4.75vh;
    margin-left: 16vw;
  }

  .text_img {
    margin-right: 1.5vw;
    letter-spacing: 1px;
  }

  .text_div_down {
    margin-bottom: 5.5vw !important ;
    margin-left: 6.5vw;
    letter-spacing: 1px;
  }

  .text_div_down_end {
    margin-top: 5.5vw;
    margin-bottom: 7vw !important ;
    margin-left: 6.5vw;
    letter-spacing: 1px;
  }
}

/* high res section */

@media (min-width: 651px) {
  .color_pallet {
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 2.2vw;
  }

  .color_pallet p {
    font-size: 0.7vw;
    margin: 0;
  }

  .color_pallet input {
    width: 70%;
    height: auto;
    margin-left: 1vw;
    outline: none;
  }

  .text_box {
    top: 25.5vh;
    width: 50%;
    color: black;
    font-family: "Open Sans";
    margin-left: 5vw;
    /* height: 100%; */
    margin-bottom: 1vh;
    line-height: 1vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-left: 5vw;
  }

  .text_box_obrzeza {
    top: 18.5vh;
    color: black;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .text_box_title {
    font-size: 2.35vw;
    text-align: left;
    margin-left: 2vw;
    width: 38vw;
    bottom: 2vh;
  }

  .text_box_subtitle {
    position: relative;
    font-size: 0.9vw;
    line-height: 2.5vh;
    margin-bottom: 3.5vh;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.36px;
    margin-left: 2vw;
    width: 24vw;
  }

  .text_box_subtitle_second {
    position: relative;
    font-size: 0.9vw;
    line-height: 2.5vh;
    margin-bottom: 3vh !important;
    margin-top: 3vh !important  ;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.36px;
    margin-left: 2vw !important;
  }
  .text_box_item p {
    margin: 1vh;
  }

  .text_box_item {
    font-size: 0.9vw;
    line-height: 0vh;
    letter-spacing: 0.36px;
    margin-bottom: 2.5vh !important;
  }

  .text_box_okucia {
    position: absolute;
    background-color: transparent;
    font-family: "Open Sans, Regular";
    top: 22vh;
    width: 50%;
    color: black;
    font-family: "Open Sans";
    margin-left: 5vw;
    /* height: 100%; */
    margin-bottom: 1vh;
    line-height: 1vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-left: 5vw;
  }

  .text_box_okleiny {
    position: absolute;
    background-color: transparent;
    font-family: "Open Sans, Regular";
    top: 14vh;
    width: 50%;
    color: black;
    font-family: "Open Sans";
    margin-left: 5vw;
    /* height: 100%; */
    margin-bottom: 1vh;
    line-height: 1vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-left: 5vw;
  }

  .text_box_item_okleiny {
    font-size: 0.9vw;
    line-height: 0vh;
    letter-spacing: 0.36px;
    margin-bottom: 2.5vh !important;
  }

  .text_box_item_okleiny div {
    margin-bottom: 0.5vh !important;
  }

  .text_box_item_okucia {
    font-size: 0.9vw;
    line-height: 0vh;
    letter-spacing: 0.36px;
    margin-bottom: 2.5vh !important;
  }

  .text_box_item_okucia div {
    margin-bottom: 0.5vh !important;
  }

  .text_box_subtitle_second_okleiny {
    position: relative;
    font-size: 0.9vw;
    line-height: 2.5vh;
    margin-bottom: 2vh !important;
    margin-top: -1vh !important  ;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.36px;
    margin-left: 2vw !important;
  }

  .text_space {
    margin-left: 2vw !important;
    margin-top: 1.5vh !important;
    margin-bottom: 2vh !important;
  }

  .text_img {
    margin-right: 0.75%;
  }

  .category_img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: relative;
  }
}
/*end of full screen sec*/

.text_box {
  position: absolute;
  background-color: transparent;

  font-family: "Open Sans, Regular";
}

.text_box_obrzeza {
  position: absolute;
  background-color: transparent;

  margin-left: 5vw;
  /* height: 100%; */
  margin-bottom: 1vh;
  font-family: "Open Sans";
}

.text_box_title {
  position: relative;
  margin-bottom: 3%;
  font-weight: bold;
  /* font: Bold 45px/61px; */
  line-height: 6.5vh;
  letter-spacing: 0.9px;
}

.text_box_item {
  font-family: "Open Sans, Regular";
  position: relative;
}

.entp_imgs {
  position: relative;
  top: 1vh;
  margin-top: 2vh;
  margin-left: 2vw;
  display: inline-flex;
}

.entp_imgs_okucia {
  position: relative;
  top: 0vh;
  margin-top: 2vh;
  margin-left: 2vw;
  display: inline-flex;
}

.entp_imgs_bejca {
  position: relative;
  top: -2vh;
  margin-top: 5vh;
  margin-left: -0.5vw;
  display: inline-flex;
}

.entp_imgs_libra {
  position: relative;
  top: 1vh;
  margin-top: 2vh;
  margin-left: 0.5vw;
  display: inline-flex;
}

.entp_imgs_wkret {
  position: relative;
  top: 1vh;
  margin-top: 2vh;
  margin-left: 1.5vw;
  display: inline-flex;
}

.entp_img {
  top: 1.5vh;
  left: 0.5vw;
  margin-right: 0.2vw;
  width: 9vw;
}

.entp_img_lakiery {
  top: 1.5vh;
  left: 0.5vw;
  margin-right: 0.2vw;
  width: 8vw;
}

.entp_imgs_down {
  position: relative;
  top: 8vh;
  margin-top: 2vh;
  margin-left: -18vw;

  display: inline-flex;
}

.entp_img_down {
  position: relative;
  top: 1vh;
  margin-right: 1.5vw;
  width: 9vw;
}

.entp_imgs_down_okucia {
  position: relative;
  top: 8vh;
  margin-top: 2vh;
  margin-left: -27.3vw;
  display: inline-flex;
}

.entp_img_down_okucia {
  top: 2vh;
  left: 1vw;
  margin-right: 0.1vw;
  width: 9vw;
}

.entp_img_calif {
  top: 0.8vh;
  left: -3.75vw;
  margin-right: 0.1vw;
  width: 9vw;
  height: 10vh;
  position: relative;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.carousel-style {
  height: 100% !important;
  width: 50vw !important;
}

.carousel-inner > .item {
  height: 50%;
}

.myAnim {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
