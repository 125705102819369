.account {
  position: relative;
  margin-top: 5vh;
  left: 0;
  top: 0;
  width: 95vmin;
  margin-left: 5vmin;
  height: auto;
}

.account h3 {
  text-align: center;
  margin-bottom: 3vmin;
  width: 35vmin;
  font-weight: bold;
  font-size: 3vmin;
}

.account input {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 4vmin;
  width: 35vmin;
  padding: 2vmin;
  outline: none;
  margin-bottom: 2vh;
  font-size: 1.2vmin;
}

.account button {
  background-color: #e97a41;
  width: 35vmin;
  height: 5vmin;
  color: white;
  border-radius: 50px;
  border: none;
  outline: none;
  font-size: 1.2vmin;
}

.account-fields > div {
  display: flex;
  flex-direction: column;
}

.account-fields {
  width: 95vmin;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.account-fields label {
  font-size: 1.8vmin;
}
.account-purchase-history {
  margin-top: 5vmin;
}

@media (max-width: 500px) {
  .account {
    width: 80vmin;
    margin: 0 auto;
  }
  .account h3 {
    width: 80vmin;
    font-weight: bold;
    font-size: 6.5vmin;
  }
  .account input {
    height: 18vmin;
    width: 80vmin;
    padding: 5vmin;
    outline: none;
    margin-bottom: 2vh;
    font-size: 3vmin;
  }
  .account button {
    width: 80vmin;
    height: 18vmin;
    font-size: 3vmin;
  }
  .account-fields {
    width: 80vmin;
  }
  .account-fields label {
    font-size: 3vmin;
  }
  .account-purchase-history {
    margin-top: 15vmin;
  }
}
