.show-flex {
  display: flex;
  align-items: center;
}
.shipping {
  margin-top: 10vmin;
  margin-left: 5vmin;
}

.shipping-top {
  display: flex;
  width: 80vw;
  justify-content: space-between;
}

.shipping h3 {
  font-size: 2.2vmin;
  font-weight: bold;
}

.shipping input {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 4vmin;
  width: 30vmin;
  padding: 2vmin;
  outline: none;
  margin-bottom: 2vmin;
  font-size: 1.7vmin;
}

.shipping-fields {
  margin-top: 5vmin;
  width: 65vmin;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.shipping-fields > div {
  display: flex;
  flex-direction: column;
}

.shipping-fields label {
  font-size: 1.7vmin;
  font-weight: bold;
}

.shipping p {
  font-size: 1.8vmin;
}

.shipping-options {
  display: flex;
  width: 60vmin;
  height: 23vmin;
  margin-left: 10vw;
  justify-content: space-evenly;
}

.add-border-active {
  border-color: #fbb03b !important;
}

.add-border- {
}

.shipping-options > div {
  width: 30%;
  min-width: 60px;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.shipping-options p {
  color: #a1a1a1;
  opacity: 0.5;
  text-align: center;
}

.shipping-options h5 {
  font-weight: bold;
  font-size: 1.9vmin;
  color: black;
}

.shipping-options h3 {
  font-weight: bold;
  font-size: 2vmin;
  color: black;
  opacity: 0.5;
}

.shipping-icons {
  display: flex;
  justify-content: space-evenly;
}

.shipping-icons img {
  width: 5vmin;
  height: auto;
}
.horizontal-line {
  margin-top: 2.5vh;
  width: 6vw;
  border-top: 1px solid #eeeeee;
}

.navigation {
  display: flex;
  width: 80vw;
  justify-content: space-between;
}

.navigation-return {
  display: flex;
  height: 2.9vmin;
}

.navigation-return img {
  width: 2vmin;
  height: auto;
  cursor: pointer;
}

.navigation-return p {
  font-weight: bold;
  height: 2.9vmin;
  cursor: pointer;
  font-size: 1.4vmin;
  line-height: 2.9vmin;
  margin-left: 5px;
  margin-bottom: 0;
}

.navigation-forward {
  display: flex;
  justify-content: space-between;
  width: 45vmin;
}

.navigation-forward-continue {
  background-color: white;
  width: 20vmin;
  color: black;
  border-radius: 50px;
  border: 1px solid #d8d8d8;
  height: 4vmin;
  outline: none;
  font-size: 1.4vmin;
  font-weight: bold;
  display: block;
  margin: 0;
}

.navigation-forward-laststep {
  background-color: #e97a41;
  width: 20vmin;
  color: white;
  border-radius: 50px;
  border: none;
  height: 4vmin;
  outline: none;
  font-size: 1.4vmin;
  display: block;
  margin: 0;
}

@media (max-width: 500px) {
  .shipping {
    width: 95vmin;
    margin-left: 2.5vmin;
    margin-right: 2.5vmin;
    overflow-x: hidden;
  }
  .shipping-top {
    width: 95vmin;
    margin-top: 7vh;
    margin-bottom: 5vh;
  }
  .shipping h3 {
    font-size: 5vmin;
    margin: 0 auto;
  }
  .shipping-icons {
    display: none;
  }
  .show-flex {
    display: block;
  }

  .navigation {
    width: 95vmin;
  }

  .navigation-return {
    display: flex;
    height: 2.9vmin;
    position: absolute;
    top: 18vh;
    left: 2.5vw;
  }

  .navigation-return img {
    width: 5vmin;
    height: auto;
  }

  .navigation-return p {
    font-weight: bold;
    height: 2.9vmin;
    font-size: 3vmin;
    line-height: 2.9vmin;
    margin-left: 5px;
    margin-bottom: 0;
  }
  .shipping-fields {
    margin-top: 2vh;
    width: 40vmin;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .shipping input {
    height: 18vmin;
    width: 90vmin;
    padding: 5vmin;
    outline: none;
    margin-bottom: 2vmin;
    font-size: 4vmin;
    margin-left: 2.5vmin;
  }
  .shipping-fields label {
    font-size: 3.5vmin;
    margin-left: 2.5vmin;
  }
  .shipping-options {
    display: flex;
    flex-wrap: wrap;
    width: 90vmin;
    height: 100vmin;
    margin: 0 0 2.5vmin 2.5vmin;
    justify-content: space-evenly;
  }
  .shipping-options > div {
    margin-top: 5vmin;
    width: 40%;
  }
  .shipping-options h5 {
    font-size: 3.5vmin;
  }
  .shipping-options p {
    font-size: 3vmin;
  }
  .navigation-forward {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 80vmin;
    height: 35vmin;
    margin: 0 auto;
    margin-top: 5vmin;
  }

  .navigation-forward-continue {
    width: 80vmin;
    height: 15vmin;
    font-size: 3vmin;
    font-weight: bold;
    margin: 0;
    order: 1;
  }

  .navigation-forward-laststep {
    width: 80vmin;
    height: 15vmin;
    font-size: 3vmin;
    font-weight: bold;
    margin: 0;
    order: 0;
  }
}
