/*https://www.siepomaga.pl/ocalic-tosie?ts=fbr22014&fbclid=IwAR3_9cSHvlC1TfXPeQSdJ7UjaQu7AXEY8pgev8zrTmyHOMNfWgBrVqvOiRo*/

@media (max-width: 651px) {
  .cards_section {
    position: relative;
    flex-wrap: wrap;  
    flex-direction: column;       
    justify-content: center;          
    width: 100%;    
    top: 86vh; 
  }

  .card_img{    
    width: 85%;          
    margin-left: 10%;
  }
}

@media (min-width: 651px) {
  .cards_section {
    justify-content: left;    
    display:  flex;             
    position: relative;
    flex-wrap: wrap;  
    flex-direction: row;         
    width: 100%;          
    
  }

  .card_sec{
    position: relative;    
  }

  .card_img_div{    
    pointer-events: none;

  }

  .card_img{
        
    height: 100% ;
    width: 31vw !important;              
    margin-left: 12%;     
    
  }
  .card_div{          
    margin-top: 2vh;
  }

}

.card_img:hover { 
/*   z-index: 1;
  animation-name: rotate; 
  animation-delay: 0.3s;        
  animation-duration: 1.3s;     
  animation-fill-mode: forwards;
  animation-iteration-count: 1;   */
}

.card_img:hover::after {   
/*   
  display: block;
  pointer-events: none;*/
} 

/* 
  animation: flip-scale-down-diag-2 0.5s linear both;

.card_img:hover {      
  z-index: 1;   
  animation: flip-scale-down-diag-2 0.5s linear both;
  
}
 */

/* animation-name: rotate; 
    animation-delay: 0.25s;        
    animation-duration: 1.15s;     
    animation-fill-mode: forwards;
*/

@keyframes rotate {
    from {      
        transform: rotateY(30deg);}
    to {
        transform: rotateX(-30deg) rotateY(Odeg);}
}

@keyframes flash {
  0%
   {    
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
