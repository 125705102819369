@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact_button input {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  outline: none;
}

.shop_button input {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  outline: none;
}

.welcome_button input {
  width: 100%;
  height: 100%;
  outline: none;
}

@media (max-width: 550px) {
  .contact img,
  .shop img,
  .welcome img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
  }

  .contact,
  .welcome,
  .shop {
    width: 100vw;
    height: 85vh;
    position: relative;
  }

  .welcome_button,
  .shop_button {
    position: absolute;
    width: 70vw;
    height: 6.5vh;
    left: 15vw;
    top: 38vh;
    object-fit: cover;
  }

  .contact_button {
    position: absolute;
    width: 60vw;
    height: 6.5vh;
    left: 20vw;
    top: 38vh;
    object-fit: cover;
  }

  .contact_button input,
  .welcome_button input,
  .shop_button input {
    width: 100%;
    height: 100%;
  }

  .contact h1,
  .welcome h1,
  .shop h1 {
    position: absolute;
    color: white;
    left: 10vw;
    top: 30vh;
    width: 80vw;
    font-size: 8vw;
    font-weight: bold;
    letter-spacing: 0.9px;
    text-align: center;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .contact h5,
  .shop h5,
  .welcome h5 {
    display: none;
  }
}

@media (min-width: 551px) {
  .contact img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .shop img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .welcome img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .intro_page {
    display: flex;
  }
  .contact {
    min-width: 250px;
    width: 30vw;
    height: 89.5vh;
    position: relative;
  }
  .shop_welcome {
    min-width: 400px;
    width: 70vw;
    max-height: 89.5vh;
  }
  .welcome {
    width: 100%;
    height: 50%;
    position: relative;
  }
  .shop {
    width: 100%;
    height: 50%;
    position: relative;
  }
  .contact_button {
    position: absolute;
    width: 184.2px;
    height: 35px;
    left: 9vh;
    top: 75%;
  }
  .shop_button {
    position: absolute;
    width: 220px;
    height: 35px;
    left: 9vh;
    top: 72%;
  }
  .welcome_button {
    position: absolute;
    width: 220px;
    height: 35px;
    left: 9vh;
    top: 62%;
  }
  .contact h1 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 59%;
    font-size: 1.72vw;
    font-weight: bold;
    letter-spacing: 0.9px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .contact h5 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 67%;
    letter-spacing: 0.36px;
    font-weight: 100;
    font-size: 0.69vw;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .shop h1 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 40%;
    font-size: 1.72vw;
    font-weight: bold;
    letter-spacing: 0.9px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .shop h5 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 56%;
    letter-spacing: 0.36px;
    font-weight: 100;
    font-size: 0.69vw;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .welcome h1 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 30%;
    font-size: 1.72vw;
    font-weight: bold;
    letter-spacing: 0.9px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .welcome h5 {
    position: absolute;
    color: white;
    left: 9vh;
    top: 46%;
    letter-spacing: 0.36px;
    font-weight: 100;
    font-size: 0.69vw;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
