.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  min-height: 600px;
  // max-height: 100vh;

  &-header {
    width: 100%;
    margin-bottom: 10px;
    height: 10vh;

    .products-found {
      float: left;
      margin: 0;
      margin-top: 8px;
    }

    @media (min-width: 650px) {
      .sort {
        float: right;
        margin-right: 2%;
        margin-left: 1%;
        font-weight: bold;
        &-select-sort {
          background-color: #fff;
          outline: none;
          border: 1px solid #d8d8d8;
          border-radius: 50px;
          margin-left: 1vw;
          width: auto;
          max-width: 11vw;
          max-width: 18vw;
          font-size: 1.35vh;
          height: 4vh;
          cursor: pointer;

          &:hover {
            border: 1px solid #5b5a5e;
          }
        }
      }
    }

    @media (max-width: 650px) {
      .sort {
        float: left;
        margin-right: 2%;
        margin-left: 1%;
        font-weight: bold;
        margin-bottom: 1.5vh;
        &-select-sort {
          background-color: #fff;
          outline: none;
          border: 1px solid #d8d8d8;
          border-radius: 50px;
          margin-left: 1vw;
          width: 17vw;
          font-size: 1.35vh;
          height: 4vh;
          cursor: pointer;

          &:hover {
            border: 1px solid #5b5a5e;
          }
        }
      }
    }
  }

  .shelf-item {
    width: 25%;
    position: relative;
    text-align: left;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 30px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid #eee;
    }

    .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #1b1a20;
      cursor: default;
    }

    &__thumb {
      img {
        min-height: 17vh;
        min-width: 11vw;
        height: 22vh;
        width: 15vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
      }
    }
    //nazwa produktu
    @media only screen and (min-width: 600px) {
      &__title {
        position: relative;
        padding: 0vh;
        margin-top: 1vh;
        font-size: 0.8vw;
        //font-size: 1em;
        height: 6.2vh;
        // &::before {
        //   content: "";
        //   width: 20px;
        //   height: 2px;
        //   background-color: #eabf00;
        //   position: absolute;
        //   bottom: 0;
        //   left: 5%;
        //   //margin-left: 6px;
        // }
      }
      &__price {
        height: 5.7vh;

        .val {
          b {
            font-size: 1.5em;
          }
        }

        .installment {
          color: #9c9b9b;
        }
      }

      &__buy-btn {
        background-color: #e97a41;
        border-radius: 40px;
        color: #fff;
        padding: 1.25vh;
        margin-top: 2.5px;
        cursor: pointer;
        text-align: center;
        // border-bottom: 2px solid #151419;
        font-size: 0.85vw;
        height: 2.5vw;
        transition: background-color 0.2s;

        &:hover {
          background-color: #fff;
          color: #e97a41;
          border-color: #e97a41;
          border-style: solid;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      &__thumb {
        // height: 30vh;
        img {
          // min-height: 17vh;
          // min-width: 11vw;
          height: 200px;
          width: 100%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          object-fit: contain;
        }
      }
      &__title {
        position: relative;
        padding: 0 1px;
        height: 6vh;
        font-size: 3.1vw;
        // margin-bottom: 4.2vh;
        text-align: center;
        // &::before {
        //   content: "";
        //   width: 20px;
        //   height: 2px;
        //   background-color: #eabf00;
        //   position: absolute;
        //   bottom: 0;
        //   left: 5%;
        //   //margin-left: 6px;
        // }
      }
      &__price {
        position: relative;
        height: 5vh;
        font-size: 3.5vw;

        .val {
          text-align: center;
          b {
            font-size: 1.5em;
          }
        }

        .installment {
          color: #9c9b9b;
        }
      }

      &-buy_button {
        width: 35vw;

        background-color: #e97a41;
        font-size: 2.7vw;
        font-weight: bold;
        margin: 0 auto;
        height: 7vw;
        line-height: 7vw;
        border-radius: 40px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.2s;

        &:hover {
          background-color: #fff;
          color: #e97a41;
          border-color: #e97a41;
          border-style: solid;
        }
      }
    }
  }
}

.get-sites {
  position: relative;
  display: inline-flex;
}
