.quantity-filter-sites {
  position: relative;
  width: 100%;
  @media only screen and (min-width: 650px) {
    text-align: center;
  }

  @media only screen and (max-width: 650px) {
    text-align: center;
  }

  @media only screen and (max-width: 650px) {
    &-input {
      min-width: 5vw;
      max-width: 7vw;
    }

    &-button-switch {
      background-color: black;
      color: white;
      transition: background-color 0.2s;
      border-color: white;
      border-style: solid;
      font-size: 3.5vw;
      &:hover {
        background-color: #fff;
        color: black;
        border-color: black;
        border-style: solid;
      }
    }

    &-button {
      background-color: #e97a41;
      color: white;
      transition: background-color 0.2s;
      border-color: white;
      border-style: solid;
      font-size: 3.5vw;
      &:hover {
        background-color: #fff;
        color: #e97a41;
        border-color: #e97a41;
        border-style: solid;
      }
    }
  }

  @media only screen and (min-width: 650px) {
    &-input {
      min-width: 1vw;
      max-width: 2vw;
    }
    &-button-switch {
      background-color: black;
      color: white;
      transition: background-color 0.2s;
      border-color: white;
      border-style: solid;

      &:hover {
        background-color: #fff;
        color: black;
        border-color: black;
        border-style: solid;
      }
    }

    &-button {
      background-color: #e97a41;
      color: white;
      transition: background-color 0.2s;
      border-color: white;
      border-style: solid;

      &:hover {
        background-color: #fff;
        color: #e97a41;
        border-color: #e97a41;
        border-style: solid;
      }
    }
  }

  &-desc {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }
}

filter-sites-break {
  white-space: pre-wrap;
}
