.filters {
  width: 13.5vw;
  margin-right: 1vw;

  .star-button-container {
    text-align: center;
    small {
      color: #aaa;
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  .filter-div {
    border: 1px solid;
    background-color: transparent;
    border-color: #e6e6e6;
    margin-right: 5px;
    margin-bottom: 0.25vw;
    position: relative;
    //font-size: 0.8vw;
    &-items {
      display: block;
      margin-top: 1.1vh;
      margin-top: 0.5vh;
    }
  }

  .filter-arrow-down {
    transform: rotate(-90deg);
    cursor: pointer;
    width: 0.6vw;
    height: 1vh;
  }

  .filter-arrow-up {
    transform: rotate(90deg);
    cursor: pointer;
    height: 1vh;
  }

  .filter-producer-dd {
    border: 1px solid;
    background-color: transparent;
    border-color: #e6e6e6;
    margin-top: 0.25vw;
    margin-bottom: 0.25vw;
    position: relative;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  .filter-div-price {
    border: 1px solid;
    background-color: transparent;
    border-color: #e6e6e6;
    margin-right: 5px;
    margin-bottom: 0.25vw;
    position: relative;
    font-size: 0.8vw;
  }

  .filter-div-price-btns > div {
    font-size: 0.8vw;
  }

  .filter-btn {
    margin-top: 0.5vw;
    font-weight: bold;
    background-color: white;
    font-size: 1vw;
    border: none;
  }

  .title {
    margin-top: 2px;
    margin-bottom: 20px;
    font-size: 1vw;
  }

  &-available-size {
    display: block;
    margin-bottom: 10px;
    /* Customize the label (the container) */
    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 50px;
      height: 35px;
      font-size: 0.8em;
      margin-bottom: 8px;
      margin-right: 8px;
      border-radius: 50%;
      line-height: 35px;
      text-align: center;

      /* On mouse-over, add a grey background color */

      /* When the checkbox is checked, add a blue background */

      /* Show the checkmark when checked */

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom checkbox */
      .checkmark {
        height: 2vh;
        width: 95%;
        margin-bottom: 0.2vh;
        position: relative;
        display: block;
      }
    }
  }
}

// input[type='checkbox'] {
// 	margin-top: 1px;
// }

// input[type='checkbox'] {
// 	-webkit-font-smoothing: antialiased;
// 	text-rendering: optimizeSpeed;
// 	width: 1.5vw;
// 	height: 1.25vw;
// 	margin: 0;
//   margin-right: 3px;
//   margin-left: 3px;
// 	display: block;
// 	float: left;
// 	position: relative;
//   cursor: pointer;

// }

// input[type='checkbox']:after {
// 	content: "";
// 	vertical-align: middle;
// 	text-align: center;
// 	line-height: 13px;
// 	position: absolute;
// 	cursor: pointer;
// 	width: 1.5vw;
// 	height: 1.25vw;
// 	left: 0;
// 	top: 0;
//   font-size: 10px;

// 	-webkit-box-shadow: inset 1px 1px 1px #D4D4D4, 1px 1px 1px #D4D4D4;
// 	-moz-box-shadow: inset 1px 1px 1px #D4D4D4, 1px 1px 1px #D4D4D4;
// 	box-shadow: inset 1px 1px 1px #D4D4D4, 1px 1px 1px #D4D4D4;
// 	background: white;
// }

// input[type='checkbox']:hover:after, input[type='checkbox']:checked:hover:after {
// 	background: black;
// 	content: '\2714';
// 	color: #fff;
// }

// input[type='checkbox']:checked:after {
// 	background: black;
// 	content: '\2714';
// 	color: #fff;
// }

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}

.filter-button {
  background-color: #e97a41;
  color: white;
  font-size: 0.8vw;
  transition: background-color 0.2s;
  border-color: white;
  border-style: solid;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 40px;

  &:hover {
    background-color: #fff;
    color: #e97a41;
    border-color: #e97a41;
    border-style: solid;
  }

  .filter-price-box {
    margin-right: 16%;
    margin-left: 15%;
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .filter-checkbox {
    margin-left: 0.2vw;
    width: 5vw;
    float: left;
    height: 4vw;
    &-label {
      font-size: 4vw;
      width: 45vw;
      left: 0;
    }
  }

  .filter-btn {
    margin-top: 0.5vw;
    font-weight: bold;
    background-color: white;
    font-size: 4vw !important;
    border: none;
  }

  .filter-button {
    background-color: #e97a41;
    color: white;
    font-size: 2.8vw;
    transition: background-color 0.2s;
    border-color: white;
    border-style: solid;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 40px;

    &:hover {
      background-color: #fff;
      color: #e97a41;
      border-color: #e97a41;
      border-style: solid;
    }

    .filter-price-box {
      margin-right: 16%;
      margin-left: 15%;
      width: 50%;
    }
  }

  .title {
    margin-top: 2px;
    margin-bottom: 20px;
    font-size: 4.5vw !important;
  }
}

@media only screen and (min-width: 600px) {
  .filter-checkbox {
    margin-left: 0.2vw;

    &-label {
      font-size: 0.85vw;
      left: 0;
    }
    &-text {
      font-size: 0.95vw;
      left: 0;
    }
  }
}
