.container {
}

.main-section {
  width: 100%;
  height: 89.5vmin;
}

.main-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  outline: none;
}

.main-section h3 {
  position: absolute;
  left: 25vmin;
  top: 40vmin;
  color: white;
  font-size: 8vmin;
}

.main-section h4 {
  position: absolute;
  left: 25vmin;
  top: 49vmin;
  color: white;
  font-size: 7vmin;
}

.main-section > div {
  position: absolute;
  left: 25vmin;
  top: 65vmin;
  color: white;
}

.square {
  position: relative;
  left: 0;
  top: -10vmin;
  height: 10vmin;
  width: 75%;
  background-color: white;
  border-radius: 0 50px 0 0;
}

.free-shipping {
  display: flex;
  align-items: center;
}

.customer-satisfaction {
  display: flex;
  align-items: center;
}

.price-guarantee {
  display: flex;
  align-items: center;
}

.display-block {
  display: block;
}

.display-block p {
  margin: 0;
  font-size: 1.4vmin;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.assets {
  position: relative;
  width: 75%;
  margin-left: 10vmin;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.assets > div {
  margin-top: 3vmin;
  width: 33%;
}

.assets img {
  margin-right: 5px;
  outline: none;
  width: 6vmin;
  filter: brightness(100%);
}

.display-flex p {
  margin: 0;
  font-size: 1.2vmin;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

.display-flex input {
  margin-right: 5px;
  outline: none;
  width: 5vmin;
}

.categories {
  margin-top: 5%;
  display: block;
}

.row-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  margin-left: 10%;
}

.row-1 div {
  width: 11vw;
  border-radius: 50%;
  outline: none;
}

.row-1 div input {
  width: 100%;
}

.row-1 div p {
  font-size: 2vmin;
  font-weight: bold;
  text-align: center;
}

.frequently-bought {
  margin-top: 2%;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
}

.frequently-bought-gallery {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 8vh;
  margin-left: 10%;
  margin-bottom: 20vh;
}
.frequently-bought-gallery > div {
  width: 25%;
  height: 20vw;
}

.frequently-bought-gallery p {
  margin-top: 2vh;
  font-size: 1.9vmin;
  text-align: center;
}

.frequently-bought-gallery h5 {
  margin-top: 2vh;
  font-size: 1.9vmin;
  font-weight: bold;
  text-align: center;
}

.frequently-bought-gallery img {
  width: 100%;
  height: 100%;
  outline: none;
  cursor: pointer;
}

.frequently-bought h1 {
  font-size: 2.5vmin;
  font-weight: bold;
}

.orange-button {
  background-color: #e97a41;
  border: none;
  border-radius: 30px;
  height: 5vmin;
  width: 17vmin;
  color: white;
  font-size: 1.4vmin;
  font-weight: bold;
}
.why-us {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.why-us h5 {
  background-color: #478ec9;
  color: white;
  width: 12vmin;
  height: 4.5vmin;
  text-align: right;
  font-size: 2.5vmin;
  line-height: 4.7vmin;
}

.why-us p {
  margin-left: 5px;
  font-size: 2.5vmin;
  line-height: 4.7vmin;
}

.why-us-list {
  margin: 0 auto;
  width: 80%;
  margin-top: 5%;
  display: flex;
  justify-content: space-evenly;
}

.why-us-list img {
  width: 7vmin;
}

.why-us-list h5 {
  margin-top: 10px;
  font-size: 1.6vmin;
  font-weight: bold;
}

.why-us-list p {
  margin-top: 10px;
  font-size: 1.1vmin;
  width: 17vw;
}

.footer {
  display: flex;
  width: 80%;
  margin-left: 10%;
  justify-content: space-evenly;
  margin-top: 10vmin;
  margin-bottom: 4vh;
}

.footer-newsletter {
  margin-top: 2vh;
  display: block;
}

.footer-newsletter-logo {
  margin-bottom: 2vh;
}

.footer-newsletter-logo img {
  width: 15vmin;
  outline: none;
}

.footer-newsletter-items {
  display: flex;
}

.footer-newsletter-items button {
  background-color: #1a6eff;
  color: white;
  border-radius: 50px;
  border: none;
  width: 17vmin;
  height: 4.5vmin;
  outline: none;
  font-size: 1.5vmin;
  font-weight: bold;
}

.footer-newsletter-items input {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 4.5vmin;
  width: 30vmin;
  padding: 2vmin;
  outline: none;
  margin-right: 1vw;
  font-size: 1.5vmin;
}

.footer-address {
  display: block !important;
}
.footer-address p {
  margin-bottom: 0;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dbdbdb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dbdbdb;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dbdbdb;
  opacity: 1; /* Firefox */
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-info h3,
.footer-account h3,
.footer-contact h3 {
  color: #e97a41;
  font-size: 1.4vmin;
  font-weight: bold;
}

.footer-info p,
.footer-info a {
  height: 5vmin;
}

.footer-info p {
  margin-bottom: 0;
  line-height: 5vmin;
}

.footer-info p,
.footer-info a,
.footer-account p,
.footer-contact p {
  font-size: 1.4vmin;
}

.footer-contact div {
  display: flex;
}

.footer-account {
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-contact a {
  font-size: 1.4vmin;
}

.newsletter {
  width: 80%;
  margin-left: 10%;
}

.newsletter-items {
  background-image: url("../images/newsletter.jpg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
  align-items: center;
  background-size: cover;
  height: 30vmin;
}

.newsletter-items h3 {
  color: white;
  width: 60vmin;
  font-size: 3vmin;
}

.newsletter-items button {
  background-color: #1a6eff;
  color: white;
  border-radius: 50px;
  border: none;
  width: 20vmin;
  height: 5vmin;
  outline: none;
  font-size: 2vmin;
  font-weight: bold;
}

.newsletter-items input {
  border: none;
  outline: none;
  border-radius: 50px;
  height: 5vmin;
  width: 30vmin;
  padding: 2.5vmin;
  outline: none;
  font-size: 2vmin;
}

.redirect-section {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-left: 10%;
  margin-top: 10vmin;
}

.redirect-section-about {
  width: 50%;
}

.redirect-section-contact {
  width: 50%;
}

.redirect-section-about img {
  width: 100%;
  object-fit: cover;
  height: 83vmin;
  outline: none;
}

.redirect-section-contact img {
  width: 100%;
  object-fit: cover;
  height: 83vmin;
  outline: none;
}

.redirect-section h2 {
  position: relative;
  top: 50%;
  left: 20%;
  color: white;
  font-weight: bold;
  font-size: 5vmin;
}

.redirect-section button {
  position: relative;
  top: 55%;
  left: 20%;
  background-color: #e97a41;
  color: white;
  border-radius: 30px;
  border: none;
  width: 28vmin;
  height: 6vmin;
  font-size: 1.5vmin;
  font-weight: bold;
  outline: none;
}

.check-offer-1 button,
.check-offer-2 button,
.check-offer-3 button {
  position: relative;
  top: -17vmin;
  left: 11%;
  outline: none;
}

.check-offer-1 button,
.check-offer-2 button,
.check-offer-3 button {
  background-color: #f4f4f4;
  color: black;
  border-radius: 30px;
  border: none;
  width: 17vmin;
  height: 5vmin;
  font-size: 1.2vmin;
  font-weight: bold;
  outline: none;
}

.check-offer-1 button {
  background-color: #e97a41;
  color: white;
}

.check-offer-1 h3,
.check-offer-2 h3,
.check-offer-3 h3 {
  position: relative;
  top: -32vmin;
  left: 11%;
  color: white;
  font-size: 3.2vmin;
}

.check-offer-1 h3 {
  color: #e97a41;
  width: 10vmin;
}

.check-offer-1 {
  width: 50%;
}

.check-offer-2,
.check-offer-3 {
  width: 23%;
  margin-left: 2%;
}

.check-offer-1 img {
  width: 80%;
  object-fit: contain;
  height: 50vmin;
  outline: none;
  cursor: pointer;
}

.check-offer-2 img,
.check-offer-3 img {
  width: 100%;
  object-fit: cover;
  height: 50vmin;
  outline: none;
}

.check-offer {
  margin-top: 3%;
  display: flex;
  margin-left: 10%;
  width: 80%;
}

@media (max-width: 500px) {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
  }

  .square {
    width: 100vw;
    border-radius: 0;
    height: 20vh;
  }
  .assets {
    flex-direction: column;
    margin: 0;
    margin-top: 7vh;
    width: 100%;
  }

  .main-section h3 {
    font-size: 11vmin;
    left: 0;
    margin-left: 8vw;
    top: 28vmin;
    width: 95vw;
    text-align: left;
  }

  .main-section h4 {
    margin-top: 5vmin;
    font-size: 11vmin;
    width: 95vw;
    margin-left: 8vw;
    left: 0;
    top: 35vmin;
    text-align: left;
  }

  .display-flex {
    margin-top: 10vmin;
    margin-left: 8vmin;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .display-flex p {
    font-size: 2.5vmin;
    font-weight: bold;
  }
  .display-flex input {
    width: 8vmin;
    vertical-align: middle;
  }

  .assets {
    width: 100%;
  }

  .assets img {
    margin-right: 15px;
    outline: none;
    width: 9vmin;
    filter: brightness(100%);
  }

  .display-block p {
    font-size: 2.5vmin;
  }

  .assets > div {
    margin-top: 3vmin;
    width: 70%;
    margin-left: 15%;
  }

  .categories {
    margin-top: 27vh;
  }

  .row-1 {
    flex-wrap: wrap;
  }

  .row-1 div {
    width: 39vw;
    margin: 0 auto;
  }

  .row-1 div input {
    width: 100%;
  }

  .row-1 div p {
    font-size: 3vmin;
  }

  .orange-button {
    display: none;
  }

  .frequently-bought-gallery {
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
  }

  .frequently-bought-gallery > div {
    width: 100%;
    height: auto;
    margin-top: 2vh;
  }

  .frequently-bought-gallery h5 {
    font-size: 3vmin;
    font-weight: bold;
  }

  .frequently-bought-gallery p {
    font-size: 3vmin;
    font-weight: bold;
    color: white;
    border-radius: 50px;
    width: 35vw;
    margin-left: auto;
    margin-right: auto;
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    background-color: #e97a41;
  }

  .frequently-bought-gallery img {
    width: 100%;
    height: auto;
  }

  .frequently-bought h1 {
    width: 100%;
    font-size: 5vmin;
    text-align: center;
    margin-top: 5vh;
  }

  .why-us h5 {
    font-size: 5vmin;
    width: auto;
    height: auto;
  }

  .why-us p {
    font-size: 5vmin;
  }

  .why-us-list {
    flex-direction: column;
    width: 60vmin;
    margin: 0 auto;
    margin-top: 5vmin;
    align-items: center;
    text-align: center;
  }

  .why-us-list img {
    width: 20%;
    margin: 0;
  }

  .why-us-list h5 {
    font-size: 3.5vmin;
    margin-left: 0;
  }

  .why-us-list p {
    font-size: 2.5vmin;
    width: auto;
    margin-left: 0;
  }

  .check-offer {
    display: none;
  }

  .newsletter {
    height: 35vh;
    width: 100vw;
    margin: 0;
    margin-top: 5vh;
  }

  .newsletter-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .newsletter-items h3 {
    color: white;
    width: 100vmin;
    text-align: center;
    font-size: 6vmin;
  }

  .newsletter-items button {
    height: auto;
    top: 32vmin;
    width: auto;
    font-weight: bold;
    font-size: 4vmin;
    padding: 10px 15px;
  }

  .newsletter-items input {
    height: 10vmin;
    width: 80vmin;
    font-size: 3vmin;
  }

  .footer-newsletter {
    display: none;
  }

  .redirect-section {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .redirect-section button {
    font-size: 2.5vmin;
    font-weight: bold;
    width: auto;
    height: auto;
    padding: 10px 15px;
  }
  .footer-info h3,
  .footer-account h3,
  .footer-contact h3 {
    font-size: 4vmin;
  }
  .footer-info p,
  .footer-info a,
  .footer-account p,
  .footer-contact p {
    font-size: 3vmin;
  }

  .footer-info p,
  .footer-info a {
    height: 7vmin;
  }

  .footer-info p {
    margin-bottom: 0;
    line-height: 7vmin;
  }

  .footer-contact a {
    font-size: 3vmin;
  }

  .footer {
    width: 100vw;
    margin-left: 0;
  }
}
