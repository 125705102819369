a {
  font-size: inherit;
}

.product-detail-container-rightview-footer-inc-btn-quantity {
  margin-top: 0 !important;
  line-height: 49px;
}

.product-detail-container {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  height: 100%;
  display: inline-flex;

  &-leftview {
    &-gallery {
      margin-top: 5vh;
      .image-gallery {
        width: 30vw;
        height: 15vh;
        z-index: 0;
      }

      .image-gallery-svg {
        fill: #e97a41;
        width: 25px;
      }

      .image-gallery-slide img {
        width: 100%;
        height: 40vh;
        object-fit: contain;
        overflow: hidden;
        object-position: center center;
      }

      .fullscreen .image-gallery-slide img {
        margin-top: 20vh;
        margin-bottom: 20vh;
        height: 60vh;
        width: 80vw;
      }
    }

    &-header {
      font: normal normal normal 13px/18px;
      letter-spacing: 0px;
      color: #b2b2b2;
      font-size: 13px;
      display: inline-flex;
      margin-bottom: 3vh;
      &-product {
        color: black;
        font-weight: bold;
      }
    }
  }

  &-rightview {
    margin-left: 4vw;

    &-header {
      margin-top: 2vh;
      width: 50vw;
      font: normal normal normal 13px/18px;
      font-size: 13px;
      letter-spacing: 0px;
      color: black;
      font-weight: bold;
      display: inline-flex;
      margin-bottom: 3vh;
      &-elem {
        margin-right: 2vw;
      }
      &-ship {
        color: #b2b2b2;
        font-weight: normal;
        font-size: 11px;
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      margin-top: 22.5vh;
      height: 7vh;
    }

    @media screen and (max-width: 650px) {
      &-header {
        margin-top: 2vh;
        margin-left: 10vw;
        width: 50vw;
        font: normal normal normal 13px/18px;
        font-size: 13px;
        letter-spacing: 0px;
        color: black;
        font-weight: bold;
        display: inline-flex;
        margin-bottom: 3vh;
        &-elem {
          margin-right: 2vw;
        }
        &-ship {
          color: #b2b2b2;
          font-weight: normal;
          font-size: 11px;
        }
      }
      &-footer {
        margin-top: 2vh;
        margin-bottom: 10vh;
        height: 7vh;
        width: 80%;
        left: 10vw !important;
        &-inc-btn {
          order: 1;
          margin: 0 auto;
          position: relative;
          display: inline-flex;
          height: 49px;
          width: 50%;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #d8d8d8;
          border-radius: 50px;
          opacity: 1;
          font-weight: bold;
          &-btn {
            background: inherit;
            border-radius: 50px;
            border: 0px;
            margin-right: 6vw !important;
            margin-left: 6vw !important;
          }
          &-quantity {
            background: inherit;
            margin-top: 2vh;
            border-radius: 50px;
          }
        }
        &-buy-btn {
          order: 0;
          position: relative;
          height: 4vh;
          font-weight: bold;
          color: black;
          background-color: white;
          text-align: center;
          cursor: pointer;
          border: none;
          font-size: 13px;
          text-transform: uppercase;
          pointer-events: none;
        }
      }
    }

    &-body {
      padding-bottom: 150px;
      &-product {
        height: auto;
        letter-spacing: 0px;
        color: black;
        text-align: left;
        font: normal normal normal 30px/41px Open Sans;
      }
      &-product-price {
        height: auto;
        text-align: left;
        font: normal normal normal 30px/41px Open Sans;
        letter-spacing: 0px;
        color: #cecece;
        &-discount {
          text-decoration: line-through;
        }
      }
      &-product-props {
        margin-top: 5vh;
        height: 18px;
        text-align: left;
        font: normal normal normal 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #000000;
        &-desc {
          margin-top: 1vh;
          margin-bottom: 1vh;
          color: #cecece;
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    &-footer {
      position: relative;
      top: 0;
      left: 20vw;
      display: inline-flex;
      &-inc-btn {
        order: 1;
        margin: 0 auto;
        position: relative;
        display: inline-flex;
        justify-content: center;
        height: 49px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d8d8d8;
        border-radius: 50px;
        opacity: 1;
        font-weight: bold;
        &-btn {
          background: inherit;
          border-radius: 50px;
          border: 0px;
          margin-right: 1vw;
          margin-left: 1vw;
        }
        &-quantity {
          background: inherit;
          margin-top: 2vh;
          border-radius: 50px;
        }
      }
      &-buy-btn {
        order: 0;
        position: relative;
        height: 4vh;
        font-weight: bold;
        color: black;
        background-color: white;
        text-align: center;
        cursor: pointer;
        border: none;
        font-size: 13px;
        text-transform: uppercase;
        pointer-events: none;
      }
    }
  }
}

.product-detail-specification {
  // margin-top: 7vh;
  // padding: 0.05px;
  margin-left: 10vw;
  width: 80vw;
  // height: 20%;
  // display: block;
}

@media screen and (max-width: 650px) {
  .image-gallery {
    position: relative;
    width: 80vw;
    height: 20vh;
    margin-top: 5vh;
    margin-bottom: 14vh;
    z-index: 0;
  }

  .image-gallery-svg {
    fill: #e97a41;
    width: 25px;
  }

  .image-gallery-slide img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }

  .fullscreen .image-gallery-slide img {
    margin-top: 20vh;
    margin-bottom: 20vh;
    height: 60vh;
    width: 80vw;
  }
  .product-detail-specification {
    // margin-top: 7vh;
    text-align: center;
    // height: 20%;
    // display: block;
  }

  .product-detail-container {
    display: inline-block;
    &-rightview {
      margin-left: 0;
    }
  }
}
