@media (max-width: 650px) {
  .blob_btn {
    width: 60vw;
    height: 6vh;
    font-size: 3vw;
    margin-left: 10vw;
  }
}

@media (min-width: 651px) {
  .blob_btn {
    width: 14vw;
    height: 5vh;
    font-size: 0.7vw;
    margin-left: 2vw;
  }
}

.buttons {
  text-align: center;
  border-radius: 30px;
  display: inline-block;
}

.buttons img {
  margin-left: 7vw;
}

.buttons svg {
  width: 10px;
  height: 10px;
}

$cyan: #e97a41;
$dark: #ffffff;
$borderW: 2px;

.blob_btn {
  $numOfBlobs: 4;
  z-index: 1;
  position: relative;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  color: $dark;
  font-weight: bold;
  background-color: transparent;
  outline: none !important;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 30px;

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: $borderW solid $cyan;
    border-radius: 30px;
  }

  &:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: $borderW * 1.5;
    top: $borderW * 1.5;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
  }

  &:hover {
    color: $cyan;
    border-radius: 30px;
    &:after {
      transition: all 0.3s;
      left: 0;
      top: 0;
      border-radius: 30px;
    }
  }

  &__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: $cyan;
  }

  // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
  &__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url("#goo");
  }

  &__blob {
    position: absolute;
    top: $borderW;
    width: 100% / $numOfBlobs;
    height: 100%;
    background: $dark;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;

    @supports (filter: url("#goo")) {
      transform: translate3d(0, 150%, 0) scale(1.4);
    }

    @for $i from 1 through $numOfBlobs {
      &:nth-child(#{$i}) {
        left: ($i - 1) * (120% / $numOfBlobs);
        transition-delay: ($i - 1) * 0.08s;
      }
    }

    .blob_btn:hover & {
      transform: translateZ(0) scale(1.7);

      @supports (filter: url("#goo")) {
        transform: translateZ(0) scale(1.4);
      }
    }
  }
}
