@media (min-width: 400px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  .modal-main {
    position: relative;
    top: 20%;
    left: 15%;
    width: 70%;
    height: auto;
    border-radius: 10px;
    min-width: 759.91px;
  }

  .modal-main-picture {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .modal-main-picture-mobile {
    display: none;
  }

  .form-title {
    display: none;
  }

  .modal-main-picture input {
    width: 100%;
    height: auto;
    object-fit: cover;
    pointer-events: none;
  }

  .modal-main-details {
    position: absolute;
    left: 50%;
    top: 7%;
    width: 49%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-main-details > input {
    margin-top: 3.6vh;
    width: 65%;
    height: 25%;
    outline: none;
    border: none;
    background: #cee7fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 0.8vw;
  }

  .modal-main-details > label {
    font-size: 0.8vw;
    position: relative;
    top: 5%;
    left: -7%;
    margin: 0;
    width: 50%;
  }

  .message-textarea {
    width: 65%;
    height: 60%;
    outline: none;
    border: none;
    margin-bottom: 2%;
    margin-top: 4.9%;
    background: #cee7fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 0.8vw;
  }

  .modal-main-buttons {
    display: flex;
    width: 65%;
  }

  .submit.blue input {
    background: #478ec9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    height: 5vh;
    width: 100%;
    font-size: 0.8vw;
    letter-spacing: 1.2px;
    color: white;
    outline: none;
  }

  .submit.gray input {
    background: #b6babe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    height: 5vh;
    width: 100%;
    font-size: 0.8vw;
    letter-spacing: 1.2px;
    color: white;
    outline: none;
  }

  .reject input {
    background: none;
    border: none;
    width: 100%;
    height: 5vh;
    font-size: 0.8vw;
    letter-spacing: 1.2px;
    outline: none;
  }

  .statement {
    margin-top: 2%;
    display: flex;
    align-items: baseline;
    width: 85%;
    margin-left: 20%;
  }

  .statement p {
    font-size: 0.7vw;
    letter-spacing: 0.24px;
    margin-left: 0.3vw;
  }

  .alert.show {
    opacity: 1;
    font-size: 0.7vw;
    letter-spacing: 0.24px;
    color: red;
    width: 65%;
  }

  .alert.hide {
    opacity: 0;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
@media (max-width: 400px) {
  .modal-main-picture {
    display: none;
  }

  .modal-main-picture-mobile {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .modal-main-picture-mobile input {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  .modal {
    position: fixed;
    top: 15vh;
    left: 0;
    width: 100vw;
    height: 85vh;
  }
  .modal-main {
    width: inherit;
    height: inherit;
    position: relative;
  }

  .form-title {
    position: fixed;
    top: 18%;
    left: 10%;
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .form-title p {
    color: white;
    font-size: 5.8vw;
    font-weight: bold;
    width: auto;
  }

  .modal-main-details {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 12%;
    left: 10vw;
  }
  .modal-main-details > input {
    width: 80vw;
    height: 8vh;
    outline: none;
    border: none;
    background: #cee7fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 3vw;
  }

  .modal-main-details > label {
    font-size: 3vw;
    position: relative;
    margin: 0;
    width: 80vw;
    height: auto;
    left: 2vw;
    color: white;
  }
  .message-textarea {
    width: 80vw;
    height: 18vh;
    outline: none;
    border: none;
    background: #cee7fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 3vw;
  }

  .modal-main-buttons {
    display: flex;
    width: 80vw;
  }

  .submit.blue input {
    background: #478ec9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    height: 6vh;
    width: 40vw;
    font-size: 3vw;
    letter-spacing: 1.2px;
    color: white;
    outline: none;
  }

  .submit.gray input {
    background: #b6babe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    border: none;
    height: 6vh;
    width: 40vw;
    font-size: 3vw;
    letter-spacing: 1.2px;
    color: white;
    outline: none;
  }

  .reject input {
    background: none;
    border: none;
    height: 6vh;
    width: 40vw;
    font-size: 3vw;
    letter-spacing: 1.2px;
    outline: none;
    color: white;
  }
  .statement {
    margin-top: 2%;
    display: flex;
    align-items: baseline;
    width: 85%;
  }

  .statement p {
    font-size: 2.9vw;
    letter-spacing: 0.24px;
    color: white;
    margin: 0;
    margin-left: 1vw;
  }

  .alert.show {
    opacity: 1;
    font-size: 2.9vw;
    letter-spacing: 0.24px;
    color: red;
    width: 85%;
  }

  .alert.show p {
    margin: 0;
  }

  .alert.hide {
    opacity: 0;
  }
  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
