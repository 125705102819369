.register-return {
  display: flex;
  justify-content: baseline;
  margin: 0;
  position: relative;
  top: 15vh;
  left: 22vw;
  width: 60vw;
}

.register-return img {
  width: 2vmin;
  cursor: pointer;
}
.register-return p {
  font-size: 1.1vmin;
  margin-left: 5px;
  margin-bottom: 0;
  cursor: pointer;
}
.register {
  margin-top: 20vh;
  margin-left: 20vw;
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register h3 {
  margin-bottom: 8vh;
  width: 60vw;
  text-align: center;
  font-weight: bold;
  font-size: 2.5vmin;
}

.register input[type="text"],
.register input[type="password"] {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  height: 5vmin;
  width: 35vmin;
  padding: 2vmin;
  outline: none;
  margin-bottom: 2vh;
  font-size: 1.2vmin;
}

.register button {
  background-color: #e97a41;
  min-width: 35vmin;
  color: white;
  border-radius: 50px;
  border: none;
  min-height: 5vmin;
  outline: none;
  font-size: 1.2vmin;
  display: block;
  margin: 0 auto;
}

.register-fields {
  width: 50vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.register-fields > div {
  display: flex;
  flex-direction: column;
}

.register-fields label {
  font-size: 1.5vmin;
}

.register p {
  font-size: 1.3vmin;
}

@media (max-width: 500px) {
  .register-return {
    top: 3vh;
    left: 5vw;
    width: 60vw;
  }

  .register-return img {
    width: 5vmin;
    margin-bottom: 5vh;
  }
  .register-return p {
    font-size: 3vmin;
    margin-left: 5px;
    margin-bottom: 5vh;
  }

  .register {
    margin-top: 5vh;
    margin-left: 2.5vw;
    width: 95vw;
    height: auto;
  }
  .register h3 {
    width: 90vw;
    font-size: 6.5vmin;
  }
  .register input[type="text"],
  .register input[type="password"] {
    height: 18vmin;
    width: 90vmin;
    padding: 5vmin;
    margin-bottom: 2vh;
    font-size: 4vmin;
  }
  .register-fields label {
    font-size: 3.5vmin;
  }
  .register p {
    font-size: 3.5vmin;
  }

  .register button {
    width: 100vw;
    min-height: 18vmin;
    font-size: 3.5vmin;
    border-radius: 0;
    margin: 2vh 0 0 0;
  }
}
